import {
  Box,
  Flex,
  Link,
  ListItem,
  OrderedList,
  Text,
  VisuallyHidden,
} from '@chakra-ui/react';
import NextLink from 'next/link';
import React, { isValidElement, ReactNode, useMemo } from 'react';
import UIWidth from '@components/presentational/UIWidth';
import { LinkProps } from 'next/dist/client/link';
import { UrlObject } from 'url';
import Head from 'next/head';
import getConfig from 'next/config';

export type BreadcrumbItemProps = Omit<LinkProps, 'href'> & {
  label: string;
  href?: string | UrlObject;
};

export function BreadcrumbItem({ label, ...props }: BreadcrumbItemProps) {
  const nextLinkProps = { ...props } as LinkProps;

  return (
    <Flex
      as={ListItem}
      color={props.href ? 'brand.100' : null}
      aria-current={!props.href ? 'page' : null}
      flexWrap="nowrap"
      fontSize=".875rem"
    >
      {props.href && (
        <>
          <NextLink {...nextLinkProps} passHref>
            <Link as={'a'}>
              <Text cursor="pointer" _hover={{ textDecoration: 'underline' }}>
                {label}
              </Text>
            </Link>
          </NextLink>

          <Box
            as="span"
            display="inline-block"
            mx=".75rem"
            role="presentation"
            color="brand.100"
          >
            /
          </Box>
        </>
      )}
      {!props.href && (
        <Text as="strong" fontWeight="normal">
          {label}
        </Text>
      )}
    </Flex>
  );
}

export default function Breadcrumb({
  children,
  breadcrumbItems,
}: {
  children: ReactNode;
  breadcrumbItems?: BreadcrumbItemProps[];
}) {
  const host = getConfig().publicRuntimeConfig.HOST;

  // Schema.org BreadcrumbList
  const breadcrumbListSchema = useMemo(() => {
    return {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: breadcrumbItems?.map((item, index) => ({
        '@type': 'ListItem',
        position: index + 1,
        name: item.label,
        item: item.href ? `${host}${item.href}` : undefined,
      })),
    };
  }, [breadcrumbItems]);

  return (
    <UIWidth>
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(breadcrumbListSchema),
          }}
        />
      </Head>
      <Box
        as="nav"
        my={{ base: '1rem', md: '2.5rem' }}
        aria-label="breadcrumbs"
      >
        <VisuallyHidden>Vous êtes ici&nbsp;:</VisuallyHidden>
        <OrderedList display="flex" flexWrap="wrap" mx={0}>
          {children}
        </OrderedList>
      </Box>
    </UIWidth>
  );
}
