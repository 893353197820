import withUser from '@/withUser';
import FrontLayout from '@layouts/FrontLayout';
import Head from 'next/head';
import Header from '@components/Header';
import React from 'react';
import UIWidth from '@components/presentational/UIWidth';
import Breadcrumb, { BreadcrumbItem } from '@components/app/Breadcrumb';
import { Box, Heading, Text } from '@chakra-ui/react';
import BgMasqueHaut from '@/components/presentational/img/v2024/BgMasqueHaut.svg';

function Page404() {
  return (
    <FrontLayout>
      <Head>
        <meta name="robots" content="noindex, follow" />
        <title>Page non trouvée | IFprofs</title>
      </Head>
      <Header />
      <Breadcrumb
        breadcrumbItems={[
          { href: '/', label: 'Accueil' },
          { label: 'Page non trouvée' },
        ]}
      >
        <BreadcrumbItem href="/" label="Accueil" />
        <BreadcrumbItem label="Page non trouvée" />
      </Breadcrumb>
      <Box
        as="section"
        data-name="not-found"
        pb={{ base: '2.5rem', md: '3.5rem' }}
        backgroundImage={{ sm: `url("${BgMasqueHaut.src}")` }}
        backgroundRepeat={{ sm: 'no-repeat' }}
        backgroundPosition={{ sm: '0 -30rem' }}
        backgroundSize={{ sm: '512rem auto' }}
        backgroundColor={{ sm: 'brand.10' }}
        minHeight={{ md: 'calc(100vh - 29.75rem)' }}
      >
        <UIWidth>
          <Heading as="h1" variant="h1" mb="1em">
            Page non trouvée
          </Heading>
          <Text my="1em">La page demandée n'a pas pu être trouvée.</Text>
        </UIWidth>
      </Box>
    </FrontLayout>
  );
}

export default withUser(Page404);
